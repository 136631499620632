const Footer = () => {
  return (
    <div className="footer">
      <p className="foot-notes">
        recreated by <a href="mailto:masood@outlook.fr">Masood🤓</a> with react
        @{" "}
        <a href="https://www.lereacteur.io/" target="blank">
          le reacteur
        </a>
      </p>
    </div>
  );
};

export default Footer;
